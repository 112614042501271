@import '../../../styles/variables.scss';

$light-color: $light-grey;
$light-color-t: $light-grey /* -translucid */;
$dark-color: $dark-background-primary;
$dark-color-t: $dark-background-primary /* -translucid */;

.MainFirst {
  position: relative;
  &__parallax {
    aspect-ratio: 2 / 1;
  }
  &__main {
    margin: 0 auto;
    max-width: 90%;
    &--parallax {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  &__phone {
    &--container {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 5;
      display: grid;
      grid-auto-flow: column;
    }
    &--icon {
      margin-right: 4px;
      -webkit-transform: scale(0.6);
      transform: scale(0.6);
    }
    &--whatsapp {
      margin: 0 2.5% 0 5%;
      min-width: 80px;
      background-color: #f8f9faab;
      margin-right: 1rem;
      border-radius: 0.3rem;
      &:hover {
        background-color: #f9fafb;
        & svg {
          fill: $whatsapp-green-hover;
          transform: scale(1.05);
        }
      }
      &Container {
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg {
          fill: $whatsapp-green;
          transition: transform 0.3s;
          &:focus {
            fill: $whatsapp-green-hover;
          }
        }
      }
      display: flex;
      justify-content: space-around;
    }
    align-items: center;
    background-color: #f8f9faab;
    border: none;
    display: flex;
    font-size: 1.25rem;
    justify-content: center;
  }
  &__separator {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &--container {
      height: inherit;
      width: inherit;
      background-color: $main-background-color;
      border-bottom: 1px solid transparent;
      &-light::before {
        content: '.';
        color: transparent;
        position: absolute;
        height: inherit;
        width: inherit;
        background: linear-gradient(to top, $light-color, $light-color-t);
        clip-path: polygon(0 0, 100% 80%, 100% 100%, 0% 100%);
        --webkit-clip-path: polygon(0 0, 100% 80%, 100% 100%, 0% 100%);
        margin-top: -10vw;
        bottom: -1px;
      }
      &-dark::before {
        content: '.';
        color: transparent;
        position: absolute;
        height: inherit;
        width: inherit;
        background: linear-gradient(to top, $dark-color, $dark-color-t);
        clip-path: polygon(0 0, 100% 80%, 100% 100%, 0% 100%);
        --webkit-clip-path: polygon(0 0, 100% 80%, 100% 100%, 0% 100%);
        margin-top: -10vw;
        bottom: -1px;
      }
    }
  }
}

.MainFirstBody {
  color: white;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &__container {
    @include box-shadow;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
    max-width: 750px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.4rem;
  }
  &__header {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    &--left {
      margin-right: 1rem;
      &Wrapper {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & > span {
          height: 1px;
          width: 50px;
          background-color: white;
        }
      }
    }
  }
  &__title {
    width: 100%;
    & > h1 {
      font-weight: 700;
      font-size: 2.5rem;
    }
  }
  &__separator {
    margin: 1rem 0;
    height: 1px;
    width: 100%;
    background-color: white;
  }
  &__body {
    width: 100%;
    display: grid;
    grid-template-columns: 15% 1fr;
    grid-auto-rows: auto;
  }
  &__item {
    &--icon {
      &.rose {
        margin-top: 0;
        align-items: flex-start;
      }
      margin: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & > img {
        width: 100%;
      }
      & svg {
        & path,
        text {
          fill: white;
        }
      }
    }
    &--name {
      &.rose {
        margin: 0;
        & h3 {
          margin-bottom: 0;
        }
        & li {
          & > span {
            font-size: 1.25rem;
            font-weight: 500;
          }
        }
        & > div {
          & > span {
            font-size: 1.35rem;
            height: inherit;
            white-space: nowrap;
            & > img {
              height: 34px;
              margin-left: 0.5rem;
            }
          }
        }
      }
      margin: 1rem 0;
      padding: 0 1rem 0 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      & > h3 {
        margin-bottom: 0;
      }
      & small {
        white-space: nowrap;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .MainFirst {
    &__main {
      max-width: 95%;
    }
    &Body {
      &__title {
        & > h1 {
          font-size: 1.75rem;
        }
      }
      &__item {
        &--name {
          & li {
            & > span {
              font-size: 1rem !important;
              font-weight: 400 !important;
            }
          }
        }
      }
    }
  }
}
