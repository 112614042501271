.Layout {
  &__main {
    overflow-x: hidden;
  }
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
