@import "../../styles/variables.scss";

.Header {
  &.dark {
    background-color: $dark-background-secondary !important;
  }
  &.light {
    background-color: $light-grey !important;
  }
  padding: 0.5rem;
  opacity: 0.99;
  min-height: 72px;
  &__brand {
    font-weight: 500;
    font-size: 1.5rem;
    &--container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
    }
  }
  &__phone {
    font-size: 0.75rem;
    font-weight: 600;
    height: 12px;
    margin-left: 24px;
    margin-top: -4px;
    text-align: center;
  }
  &__lang {
    &--container {
      @include underline-on-hover;
      & ::before {
        border-bottom: 1px solid $main-yellow !important;
      }
    }
    &--select {
    }
    &--image {
      width: 25px;
      height: 25px;
      border: 1px solid;
      border-radius: 50%;
    }
  }
  &__expand {
    &--container {
      padding: 0.5rem;
      margin: 0.5rem;
      border: 1px solid;
      border-radius: $main-radius;
    }
    &--divider {
      display: block;
      height: 1px;
      width: 100%;
    }
  }
  &__link {
    display: block !important;
    margin: 0 auto;
    text-align: center;
    cursor: pointer !important;
    transition: color 0.15s ease-in-out background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__dropdown {
    max-width: calc(100vw - 40px);
    & a {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
    &--link {
      padding: 0.25rem 1rem;
      transition: background-color 0.3s, color 0.3s;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
    }
  }
  &__collapse {
    &--container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &--button {
      height: 40px;
      border-color: $main-yellow !important ;
      & > svg {
        fill: $main-yellow !important ;
        transform: scale(1.1);
      }
    }
  }
  &__switches {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & svg {
      fill: $main-yellow;
    }
    & > div:first-child {
      margin-right: 1rem;
    }
  }
}

.dark {
  & .Header {
    &__lang {
      &--container {
        @include underline-on-hover-color($main-yellow);
      }
      &--image {
        border-color: $dark-color-secondary;
      }
    }
    &__dropdown {
      & > .dropdown-toggle {
        color: $dark-color-primary !important;
      }
      & > .show {
        background-color: $dark-background-primary !important;
      }
      &--link {
        &:hover {
          background-color: $main-grey !important;
          &:active {
            color: $light-grey !important;
            background-color: $main-grey-active !important;
          }
        }
        &:active {
          color: $light-grey !important;
          background-color: $main-grey-active !important;
        }
      }
    }
    &__expand {
      &--container {
        border-color: $main-grey;
      }
      &--divider {
        background-color: $main-grey;
      }
    }
    &__link {
      color: $dark-color-primary !important;
    }
  }
}

/* .light {
  & .Header {
    &__lang {
      &--container {
        @include underline-on-hover-color($second-yellow);
      }
      &--image {
        border-color: $light-color-secondary;
      }
    }
    &__dropdown {
      & > .dropdown-toggle {
        color: $light-color-primary !important;
      }
      & > .show {
        background-color: $light-grey !important;
        color: $main-grey !important;
      }
      &--link {
        &:hover {
          background-color: $medium-grey !important;
          &:active {
            & > div {
              color: $light-grey !important;
            }
            background-color: $main-grey !important;
          }
        }
        &:active {
          color: $light-grey !important;
          background-color: $main-grey !important;
        }
      }
      & .dropdown-divider {
        border-color: $main-grey;
      }
    }
    &__expand {
      &--container {
        border-color: $medium-grey;
      }
      &--divider {
        background-color: $medium-grey;
      }
    }
    &__link {
      color: $light-color-primary !important;
    }
  }
} */
