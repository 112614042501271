@import './styles/variables.scss';

* {
  margin: 0%;
  padding: 0%;
  border: 0;
}

body {
  &.dark {
    background: $dark-background-primary;
    /* background: linear-gradient(
      180deg,
      rgba(101, 101, 101, 1) 0%,
      rgba(61, 61, 61, 1) 100%
    ); */
  }
  &.light {
    background: $light-background-primary;
    /* background: linear-gradient(
      180deg,
      rgba(224, 224, 224, 1) 0%,
      rgba(189, 189, 189, 1) 100%
    ); */
  }
  &.hide-recaptcha {
    & .grecaptcha-badge {
      display: none !important;
    }
  }
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  color: rgb(33, 37, 41);
}

a {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}

small {
  font-size: 0.8em;
  color: rgb(172, 172, 172);
}

#root {
  min-height: 100vh;
}

.grecaptcha-badge {
  z-index: 10000;
}

.m0 {
  margin: 0;
}

.resalt-text {
  color: #f73939;
}
